import { ActivityType } from '../cmsUtilities';


export type ActivityHeaderConfig = {
    introText?: string;
    activityPart1:  string;
    activityPart2:  string;
    activityPart3:  string;
    activityPart4?:  string;
    activityPart5?:  string;
    springboardIntro?: string;
};

type ActivityFitness = ActivityType.FITNESS | ActivityType.BLAST;

type ActivityTypeWithoutFitness = Exclude<ActivityType, ActivityFitness>;

const activityHeaderConfig: Record<ActivityTypeWithoutFitness, ActivityHeaderConfig> = {
  [ActivityType.OLD]: {
    introText: 'Let\'s Have Fun!',
    activityPart1: 'What do you know?',
    activityPart2: 'What do you do?',
    activityPart3: 'What do you think?',
    activityPart4: 'Grade-range Adaptation',
    activityPart5: 'What else can you do?',
    springboardIntro: 'Springboard to more fun!',
  },
  [ActivityType.THEME]: {
    introText: 'Let\'s Have Fun!',
    activityPart1: 'What do you know?',
    activityPart2: 'What do you do?',
    activityPart3: 'What do you think?',
    activityPart4: 'Grade Range Adaptation',
    activityPart5: 'What else can you do?',
    springboardIntro: 'Springboard to more fun!',
  },
  [ActivityType.LIFE_SKILLS]: {
    activityPart1: 'What do you know?',
    activityPart2: 'What do you do?',
    activityPart3: 'What do you think?',
  },
  [ActivityType.DISCUSSIONS]: {
    activityPart1: 'Let\'s warm up!',
    activityPart2: 'Let\'s discuss',
    activityPart3: 'Let\'s reflect',
  },
  [ActivityType.CONNECTIONS]: {
    activityPart1: 'Let\'s get ready',
    activityPart2: 'Let\'s go',
    activityPart3: 'Let\'s reflect',
  },
  [ActivityType.SERVICE_LEARNING]: {
    activityPart1: 'What do you know?',
    activityPart2: 'What do you do?',
    activityPart3: 'What do you think?',
  }
};

export const getActivityHeaderConfig = (type: ActivityType) => {
  return activityHeaderConfig[type as ActivityTypeWithoutFitness];
};