import { Vue, Component, Prop } from 'vue-property-decorator';

import CmsHorizontalCardComponent from '@/commoncomponents/CmsHorizontalCardComponent/CmsHorizontalCardComponent.vue';
import { ContentCardData } from '@/utilities/cmsUtilities';

@Component({
  name: 'cms-fitness-blast-section',
  components: {
    'cms-horizontal-card': CmsHorizontalCardComponent
  },
})
export default class FitnessBlastSectionComponent extends Vue {

  @Prop()
  title: string = '';

  @Prop()
  description: string = '';

  @Prop()
  cards: Array<ContentCardData> = [];

  get blastCards() {
    return this.cards.map(genericCard => ({
      ...genericCard,
      redirectUrl: `/resources/activity?id=${genericCard.id}&activityType=activityBlast`
    }));
  }

}
